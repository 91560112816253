import styled from "styled-components"

export const Span = styled.span`
  color: ${(props) => {
    if (props.color === "red") return "#B3001B"
    else if (props.color === "blue") return "#2F1BAC"
    else if (props.color === "yellow") return "#F9DC5C"
    else if (props.color === "green") return "#20BF55"
    else if (props.color === "pink") return "#E6ABCF"
  }};
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
`
