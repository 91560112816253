import styled from "styled-components"

export const Container = styled.div`
  margin: 0 auto 100px;
  max-width: calc(1400px + 8vw);
  padding: 30px 8vw 0;
  @media (max-width: 890px) {
    flex-direction: column;
    margin: 0 auto 64px;
    padding: 0 8vw;
  }
`

export const Text = styled.p`
  margin-top: 60px;
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 890px) {
    font-size: 16px;
    line-height: 32px;
  }
`

export const ImageWrapper = styled.div`
  float: right;
  margin-top: -60px;
  margin-left: 20px;
  @media (max-width: 890px) {
    margin: 20px 0 10px;
  }
`
