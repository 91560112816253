import React from "react"
import { ColoredSpan } from "../ColoredSpan"
import { StaticImage } from "gatsby-plugin-image"
import { Container, ImageWrapper, Text } from "./style"

export const CaseStudiesDescription = () => {
  return (
    <Container>
      <h1>
        Case <ColoredSpan text="studies" color="blue" />
      </h1>
      <ImageWrapper>
        <StaticImage
          src="../../images/case-studies-shapes.png"
          alt="shapes"
          placeholder="blurred"
          style={{ height: "100%" }}
          quality="100"
        />
      </ImageWrapper>
      <Text>
        We are blogging about tech news, startups, leadership in IT
        organizations and other IT related topics.
      </Text>
    </Container>
  )
}
