import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const Container = styled.div`
  margin: 0 auto 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(1400px + 8vw);
  padding: 0 8vw;
  @media (max-width: 890px) {
    margin: 0 auto 10px;
    padding: 0 8vw;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

export const Button = styled(Link)`
  height: 16px;
  text-decoration: none;
  text-align: center;
  color: #212529;
  padding: 0 1.5px;
  border-bottom: 1.5px solid #fff;
  ${(props) =>
    props.active &&
    css`
      border-bottom: 1.5px solid #2f1bac;
      &:hover {
        border-bottom: 1.5px solid #2f1bac;
      }
    `}
  &:before {
    display: block;
    content: attr(text);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  &:active {
    color: #2f1bac;
    border-bottom: 1.5px solid #2f1bac;
    margin-bottom: -0.75px;
  }
`

export const Dots = styled.span`
  height: 16px;
`
