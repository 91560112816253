import { Container, Text, ButtonContainer } from "./style"
import React from "react"

export const Button = ({
  text,
  to,
  white,
  center,
  setBurgerActive,
  variant,
  onClickAction,
}) => {
  console.log(onClickAction)
  console.log(setBurgerActive)
  return (
    <ButtonContainer onClick={onClickAction}>
      <Container
        onClick={() => setBurgerActive && setBurgerActive(false)}
        center={center}
        white={white}
        to={to}
        variant={variant}
      >
        <Text white={white} variant={variant}>
          {text}
        </Text>
      </Container>
    </ButtonContainer>
  )
}
