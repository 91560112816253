import { createGlobalStyle } from "styled-components"
import "@fontsource/league-spartan/900.css"
import "@fontsource/league-spartan/700.css"
import "@fontsource/league-spartan/600.css"
import "@fontsource/spartan/400.css"
import "@fontsource/spartan/500.css"
import "@fontsource/spartan/600.css"

export const GlobalStyle = createGlobalStyle`

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Spartan', sans-serif;
        color: #212529;
        scroll-padding-top: 130px;
    }

    p {
        font-family: 'Spartan', sans-serif;
        font-size: 16px;
        line-height: 26px;
    }
    
    h1 {
        font-size: 72px;
        font-family: 'League Spartan', sans-serif;
        font-weight: 700 ;
        letter-spacing: 0.05em;
    }

    h2 {
        letter-spacing: 0.05em;
        font-family: 'League Spartan', sans-serif;
        font-weight: 700 ;
        font-size: 36px;
        line-height: 64px;
    }
    
    h3 {
        font-family: 'League Spartan', sans-serif;
        font-weight: 700 ;
        font-size: 24px;
    }
    
    h4 {
        font-family: 'Spartan', sans-serif;
        font-weight: 500 ;
        font-size: 18px;
    }
    
    body,
    html,
    #root {
        height: 100%;
        scroll-behavior: smooth;
    }
    
    button, input[type="submit"] {
        font-family: 'Spartan', sans-serif;
        font-weight: 600 ;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    a,
    button {
        color: #212529;
        text-decoration: none;
        font-weight: normal;
        cursor: pointer;
    }

    a:hover {
        border-bottom: none;
    }

    a:active {
        border-bottom: none;
    }

    .slick-prev {
        width: 62px !important;
        height: 62px !important;
        left: -62px !important;
    }

    .slick-next {
        width: 62px !important;
        height: 62px !important;
        right: -62px !important;
    }
    
    @media (max-width: 890px) {
        * {
        scroll-padding-top: 80px;
    }
        h1 {
            font-size:36px;
            line-height:50px;
        }
        
        h2 {
            font-size: 24px;
            line-height:32px;
        }
        
        h3 {
            font-size:18px;
        }
        
        h4 {
            font-size: 18px;
        }

        .slick-prev {
            width: 18px !important;
            height: 18px !important;
            left: -18px !important;
        }

        .slick-next {
            width: 18px !important;
            height: 18px !important;
            right: -18px !important;
        }
    }
    
    `
