import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Button, ButtonsContainer } from "./style"

export const Pagination = ({ currentPage, numOfPages, pageName }) => {
  return (
    <Container>
      <ButtonsContainer>
        {currentPage !== 1 && (
          <>
            <Button to={`/${pageName}`}>
              <StaticImage
                src="../../images/pagination-last-arrow.png"
                alt="logo"
                height={16}
                style={{ transform: "rotate(180deg)" }}
                placeholder="blurred"
              />
            </Button>
            <Button
              to={
                currentPage === 2
                  ? `/${pageName}`
                  : `/${pageName}/${currentPage - 1}`
              }
            >
              <StaticImage
                src="../../images/pagination-next-arrow.png"
                alt="logo"
                height={16}
                style={{ transform: "rotate(180deg)" }}
                placeholder="blurred"
              />
            </Button>
          </>
        )}
        {/* {currentPage > 3 && <Dots>...</Dots>} */}
        {currentPage > 2 && (
          <Button
            to={
              currentPage - 2 === 1
                ? `/${pageName}`
                : `/${pageName}/${currentPage - 2}`
            }
          >
            {`${currentPage - 2}`}
          </Button>
        )}
        {currentPage > 1 && (
          <Button
            to={
              currentPage - 1 === 1
                ? `/${pageName}`
                : `/${pageName}/${currentPage - 1}`
            }
          >
            {`${currentPage - 1}`}
          </Button>
        )}
        <Button
          active={+true}
          to={
            currentPage === 1 ? `/${pageName}` : `/${pageName}/${currentPage}`
          }
        >{`${currentPage}`}</Button>
        {numOfPages > currentPage && (
          <Button to={`/${pageName}/${currentPage + 1}/`}>
            {`${currentPage + 1}`}
          </Button>
        )}
        {numOfPages > currentPage + 1 && (
          <Button to={`/${pageName}/${currentPage + 2}/`}>
            {`${currentPage + 2}`}
          </Button>
        )}
        {/* {currentPage < numOfPages - 2 && <Dots>...</Dots>} */}
        {currentPage !== numOfPages && (
          <>
            <Button to={`/${pageName}/${currentPage + 1}`}>
              <StaticImage
                src="../../images/pagination-next-arrow.png"
                alt="logo"
                height={16}
                placeholder="blurred"
              />
            </Button>
            <Button to={`/${pageName}/${numOfPages}`}>
              <StaticImage
                src="../../images/pagination-last-arrow.png"
                alt="logo"
                height={16}
                placeholder="blurred"
              />
            </Button>
          </>
        )}
      </ButtonsContainer>
    </Container>
  )
}
