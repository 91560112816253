import styled from "styled-components"

export const Container = styled.div`
  margin: 0 auto 100px;
  max-width: calc(1400px + 8vw);
  padding: 0 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  @media (max-width: 890px) {
    margin: 0 auto 10px;
    padding: 0 8vw;
  }
`

export const Element = styled.div`
  display: grid;
  grid-template-columns: 50% 45%;
  gap: 5%;
  height: 414px;
  @media (max-width: 890px) {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 20px;
  }
`

export const Left = styled.div`
  height: 100%;
`

export const ReadMoreWrapper = styled.div`
  float: right;
`

export const Text = styled.p`
  margin-top: 20px;
  line-height: 32px;
  margin-bottom: 30px;
`
