import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const ButtonContainer = styled.span`
  font-size: unset;
`

export const Container = styled(Link)`
  display: inline-block;
  padding: 16px 20px;
  border: none;
  background-color: #2f1bac;
  &:hover {
    background-color: #3e20ff;
  }
  &:active {
    background-color: #1e1171;
  }
  ${(props) =>
    props.white &&
    css`
      background-color: #fff;
      border: 2px solid #2f1bac;
      &:hover {
        border: 2px solid #2f1bac;
        background-color: #2f1bac1a;
      }
      &:active {
        border: 2px solid #2f1bac;
        background-color: #2f1bac4d;
      }
    `}
  ${(props) =>
    props.center &&
    css`
      margin: 0 auto;
    `}

    ${(props) =>
    props.variant === "rounded" &&
    css`
      border-radius: 48px;
      background: linear-gradient(
        270deg,
        rgba(47, 27, 172, 1),
        rgba(6, 18, 51, 1)
      );
    `}

    ${(props) =>
    props.variant === "secondary" &&
    css`
      border-radius: 48px;
      background: transparent;
      color: #2f1bac;
      border: 2px solid #2f1bac;
      &:hover > span {
        color: white;
      }

      &:hover {
        border: 2px solid #2f1bac;
      }
    `}
`

export const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #fff;
  ${(props) =>
    props.white &&
    css`
      color: #2f1bac;
    `}

  ${(props) =>
    props.variant === "secondary" &&
    css`
      color: #2f1bac;
    `}
`
