import React from "react"
import { ReadMore } from "../ReadMore"
import { Container, Left, ReadMoreWrapper, Text, Element } from "./style"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BLOCKS } from "@contentful/rich-text-types"

export const CaseStudiesArticles = ({ articles }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return <Text>{children}</Text>
      },
    },
  }
  return (
    <Container>
      {articles.map((node) => (
        <Element key={node.id}>
          <Left>
            <GatsbyImage
              image={getImage(node.thumbnail)}
              alt="case study picture"
            />
          </Left>
          <div>
            <h3>{node.title}</h3>
            {renderRichText(node.shortDescription, options)}
            <ReadMoreWrapper>
              <ReadMore desiredPath={"/case-studies/" + node.slug + "/"} />
            </ReadMoreWrapper>
          </div>
        </Element>
      ))}
    </Container>
  )
}
