import React from "react"
import { graphql } from "gatsby"
import { CaseStudiesDescription } from "../components/CaseStudiesDescription"
import { CaseStudiesArticles } from "../components/CaseStudiesArticles"
import { Layout } from "../components/Layout"
import { Pagination } from "../components/Pagination"

export default function CaseStudyList({ data, pageContext }) {
  const { currentPage, numPagesInCaseStudies } = pageContext
  return (
    <Layout>
      <CaseStudiesDescription />
      <CaseStudiesArticles articles={data.caseStudies.nodes} />
      {numPagesInCaseStudies > 1 && (
        <Pagination
          pageName="case-studies"
          currentPage={currentPage}
          numOfPages={numPagesInCaseStudies}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query CaseStudyPage($skip: Int!, $limit: Int!) {
    caseStudies: allContentfulOakTeamsCaseStudy(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        shortDescription {
          raw
        }
        thumbnail {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
        slug
      }
    }
  }
`
